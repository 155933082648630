<template>
  <div class="posts-list">
    <template v-if="postList && postList.length > 0">
      <ArticleCardComponent v-for="post in postList" :key="'post_' + post.PostId" :data="post" :hide-profile="mode === 'self' || mode === 'influencer'" />
    </template>

    <NoDataFoundComponent v-else :size="'sm'" type="full" :title="$t.getTranslation('LBL_POSTS_EMPTY')" />
  </div>
</template>

<script>
import { defineComponent, ref, onMounted, inject, defineAsyncComponent } from "vue";
import { get } from "@/utils/axios";
import { useStore } from "@/store";

// import ArticleCardComponent from "@/components/cards/ArticleCardComponent.vue";
// import NoDataFoundComponent from "@/components/NoDataFoundComponent.vue";

const ArticleCardComponent = defineAsyncComponent(() => import(/* webpackChunkName: "article-card" */ /* webpackMode: "lazy" */ "@/components/cards/ArticleCardComponent.vue"));
const NoDataFoundComponent = defineAsyncComponent(() => import(/* webpackChunkName: "no-data" */ /* webpackMode: "lazy" */ "@/components/NoDataFoundComponent.vue"));

export default defineComponent({
  name: "ArticleListComponent",
  components: {
    ArticleCardComponent,
    NoDataFoundComponent,
  },
  props: {
    data: Object,
    mode: String,
    size: { type: Number, default: 6 },
    userkey: String,
  },
  setup(props) {
    const store = useStore();

    const $t = inject("$translation");

    let page = 1;
    let lastPage = 1;

    const searchValue = ref("");

    const postList = ref([]);

    const getPostList = async () => {
      let ret = await get("/post/list", {
        showConnectedProducts: 1,
        LanguageCode: $t.getLanguage(),
        mode: props.mode,
        page: page,
        size: props.size,
        UserKey: props.userkey,
        SearchField: "MULTIPLE",
        SearchType: "LIKE",
        SearchValue: searchValue.value,
      });

      if (ret && ret.data && Array.isArray(ret.data)) {
        for (let counter = 0; counter < ret.data.length; counter++) {
          let item = ret.data[counter];
          postList.value.push(item);
        }

        lastPage = ret?.lastPage;
        page++;
      }
    };

    const loadMore = () => {
      return lastPage >= page ? getPostList() : false;
    };

    const search = (data) => {
      searchValue.value = data;
      postList.value = [];
      page = 1;

      getPostList();
    };

    onMounted(() => {
      getPostList();
    });

    return { postList, loadMore, search, store };
  },
});
</script>
